import axios from 'axios'

export const useProductRequests = () => {
  
  const getProducts = async (data={}) => { 
    /*
      page=${data.page||"1"}&
      name=${data.name||""}&
      description=${data.description||""}&
      price=${data.price||""}&
      stock=${data.stock||""}&
      slug=${data.slug||""}&
      categorySlug=${data.category||""}&
      sort=${data.sort||""}&
      allSearch=${data.allSearch||""}
    */
    
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}`,
      data:data,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}/?count=10`,
        'Authorization': `${localStorage.getItem('token')}`
      }
    })
  }

  const getProduct = async (id) => { 
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCT}?slug=${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}/${id}`,
        'Authorization': `${localStorage.getItem('token')}`
      }
    })
  }

  

  const getDiscover = async (data={}) => {
    
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}`,
      data:data,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `${localStorage.getItem('token')}`
      }
    })
  }

  const getCategories = async (count=null) => {

    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_CATEGORIES}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `${localStorage.getItem('token')}`

      }
    })

  }

  const getBrands = async (count=null) => {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_BRANDS}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `${localStorage.getItem('token')}`

      }
    })
  }
  
  return {getProducts,getProduct,getDiscover,getCategories,getBrands}
}