import React,{useState} from 'react'
import {  useParams } from 'react-router-dom'
import ProductList from '../components/Content/ProductList'
import {useProductRequests} from '../helpers/ProductRequests'

export default function NewProducts(props) {

  const {slug} = useParams()
  const [search,setSearch]=useState("")

  const [products,setProducts]=useState([])
  const [sort,setSort]=useState(props.location.state!=undefined ? props.location.state.sort : null)

  const {getProducts}=useProductRequests()
  
  const simpleCount=20

  
  const getNewProducts=async(newpage,setLoading,search,setPage)=>{
    setLoading(true)
    getProducts({sort:sort,page:newpage,allSearch:search})
    .then(async response=>{

      if(response.data.products.length<1){
        setLoading(false)
      }else{
        setPage(newpage)
        setProducts([...products,...response.data.products])
      }
    })
  }
  const getSearchedProducts=(setLoading,setPage,search)=>{
    setLoading(true)
    getProducts({sort:sort,page:1,allSearch:search})
    .then(response=>{
      if(response.data.products.length<1){
        setLoading(false)
      }else{
        setPage(2)
        setProducts(response.data.products)
      }
    })
  }
  return (
    <>
    <ProductList
    slug={slug}
    getNewProducts={getNewProducts}
    getSearchedProducts={getSearchedProducts}
    products={products}
    search={search}
    setSearch={setSearch}
    setProducts={setProducts}
    getProducts={getProducts}
    sort={props.location.state!=undefined ? props.location.state : null}
    />
    </>
  )
}
